<app-our-services *ngIf="!empresasRouter"></app-our-services>
<section>
    <div class="description">
        <span class="description-text">Estamos especializados en la implantación de <strong
                style="color: #6CACE4">soluciones integrales
                TIC.</strong></span>
    </div>
    <div class="container">
        <div class="cloud-img">
            <img src="/assets/IMG/CloudPBX/cloud-logo.png" alt="CloudPBX">
        </div>
        <div class='support-container'>
            <div class='pack-container'>
                <app-cloudpbx-card [cloud_pbx_tariff]='cloud_pbx_tariff' *ngFor='let cloud_pbx_tariff of cloud_pbx'>
                </app-cloudpbx-card>
            </div>
        </div>

    </div>
</section>
<app-our-services *ngIf="empresasRouter"></app-our-services>