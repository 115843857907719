import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { PostCodeBody, ResponsePostCode } from "../models/address.models";
import { ContactEmail } from "../models/contact.interface";

@Injectable({
  providedIn: "root",
})
export class APIService {
  apiUrl: string = `${environment.apiUrl}`;
  dataUrl: string = `${environment.dataUrl}`;
  viewContent: any;
  viewFile: string;

  saved_province: string;
  saved_town: string;
  saved_street_name: string;
  saved_street_number: string;
  saved_post_code: string;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };
  constructor(private httpClient: HttpClient) {}

  getHtmlString() {
    this.httpClient
      .get("../../../templates/customer_email.html", { responseType: "text" })
      .subscribe((data) => {
        this.viewFile = "default.html";
        this.viewContent = data;
      });
  }

  sendEmail(body: ContactEmail) {
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.httpClient.post<string>(
      this.apiUrl + "/sendemail/",
      body,
      httpOptions
    );
  }
  getFromPostCode(body: PostCodeBody) {
    return this.httpClient.post<ResponsePostCode[]>(
      this.dataUrl + "api/address_post_code",
      body,
      this.httpOptions
    );
  }

  checkFullAddress(
    province: string,
    town: string,
    street_name: string,
    street_number: string
  ) {
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append("province", province);
    urlSearchParams.append("town", town);
    urlSearchParams.append("street_name", street_name);
    urlSearchParams.append("street_number", street_number);
    let params = urlSearchParams.toString();
    return this.httpClient.get<ResponsePostCode[]>(
      this.dataUrl + "api/address_name?" + params,
      this.httpOptions
    );
  }
}
