<section>
  <div class="targets-container">
    <app-tariff-card
      [packData]="d"
      *ngFor="let d of destacados"
    ></app-tariff-card>
    <!-- <div class="targets-fondo">
            <h2 class="plan-name">PACK GOL</h2>
            <div class="border-colored">
                <div class="target">
                    <h4 class="prices">20'</h4>
                    <h5 class="decimals">61</h5>
                    <h6 class="IVAinc">IVA inc</h6>
                    <div class="vertical-divider"></div>
                    <p class="description-plan">
                        <mat-icon class="icons">wifi</mat-icon>Fibra simétrica de <strong>100Mb</strong><br>
                        <mat-icon class="icons">phone_android</mat-icon> + <strong>3 líneas</strong> moviles con
                        <strong>40Gb</strong>
                        <br>
                        <mat-icon class="icons"> local_phone</mat-icon>+ Llamadas ilimitadas<br>
                    </p>
                    
                    

                    <div class="letter-container">
                        <button class="lo-quiero" (click)='getTariffFromString("PACK GOL")'>¡Lo quiero!</button>                       
                    </div>
                   
                                         
                    
                    
                </div>
            </div>
        </div>
        <div class="targets-fondo">
            <h2 class="plan-name">PACK MARCADOR</h2>

            <div class="border-colored">
                <div class="target">
                    <h4 class="prices">24'</h4>
                    <h5 class="decimals">75</h5>
                    <h6 class="IVAinc">IVA inc</h6>
                    <div class="vertical-divider"></div>
                    <p class="description-plan">
                        <mat-icon class="icons">wifi</mat-icon><strong>Fibra simétrica de 100Mb</strong><br>
                        <mat-icon class="icons">phone_android</mat-icon>+ 3 líneas moviles con <strong>80Gb</strong><br>
                        <mat-icon class="icons"> local_phone</mat-icon>+ Llamadas ilimitadas<br>
                    </p>
                    <div class="letter-container">
                        <button class="lo-quiero" (click)='getTariffFromString("PACK MARCADOR")'>¡Lo quiero!</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="targets-fondo">
            <h2 class="plan-name">PACK TRIBUNA</h2>

            <div class="border-colored">
                <div class="target">
                    <h4 class="prices">24'</h4>
                    <h5 class="decimals">75</h5>
                    <h6 class="IVAinc">IVA inc.</h6>
                    <div class="vertical-divider"></div>
                    <p class="description-plan">
                        <mat-icon class="icons">wifi</mat-icon><strong>Fibra simétrica de 600Mb</strong><br>
                        <mat-icon class="icons">phone_android</mat-icon> + 3 líneas móviles con
                        <strong>40Gb</strong><br>
                        <mat-icon class="icons"> local_phone</mat-icon>+ Llamadas ilimitadas<br>
                    </p>

                    <div class="letter-container">
                        <button class="lo-quiero" (click)='getTariffFromString("PACK TRIBUNA")'>¡Lo quiero!</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="targets-fondo">
            <h2 class="plan-name">PACK FAMILIA PLUS</h2>

            <div class="border-colored">
                <div class="target">
                    <h4 class="prices">26'</h4>
                    <h5 class="decimals">81</h5>
                    <h6 class="IVAinc">IVA inc.</h6>

                    <div class="vertical-divider" id="vertical-divider-4"></div>

                    <p class="description-plan" id="description-plan4">
                        <mat-icon class="icons">wifi</mat-icon><strong>Fibra simétrica de 600Mb</strong><br>
                        <mat-icon class="icons">phone_android</mat-icon>+ 3 líneas móviles con <strong>80Gb</strong><br>
                        <mat-icon class="icons"> local_phone</mat-icon>+ Llamadas ilimitadas<br>
                        <mat-icon class="icons"> ring_volume</mat-icon>+ Fijo
                    </p>
                    <div class="letter-container">
                        <button type="button" class="lo-quiero" (click)='getTariffFromString("PACK FAMILIA PLUS")'>¡Lo
                            quiero!</button>
                    </div>
                </div>
            </div>
        </div>
 -->
  </div>

  <button
    mat-flat-button
    color="black"
    (click)="showInfo = !showInfo"
    style="
      margin: 2em auto;
      display: block;
      background-color: #6cace47c;
      color: white;
    "
  >
    Más info
  </button>
  <p class="info" *ngIf="showInfo">
    *Solo durante los 6 primeros meses. A partir del séptimo mes, los precios
    serán 39.40€ (PACK GOL), 47.32€ (PACK MARCADOR), 47.32€ (PACK TRIBUNA)
    21.27€ (PACK FAMILIA PLUS). Precios SIN IVA.
  </p>

  <div class="welcome">
    <h2 data-aos="fade-down" data-aos-duration="1500">
      SENTE A CHAMADA <strong style="color: #6cace4">DO CELTISMO</strong>
    </h2>
    <div class="divider" data-aos="fade-right" data-aos-duration="1500"></div>
  </div>
</section>
