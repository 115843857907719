<mat-card class="card">
    <mat-card-content>
        <h2 class='client-name'>{{voice.client}}</h2>
        <div class="card-text">
            <p class="text">{{voice.message}}</p>
        </div>
        <div class="card-image">
            <img mat-card-image src="{{image_source}}" alt="{{voice.image}}">
        </div>
    </mat-card-content>
</mat-card>