import { Component, OnInit } from '@angular/core';
import { CloudPBX, Support } from 'src/app/models/corporate.interface';
import { cloud_pbx } from 'src/app/data/corporateData';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.scss'],
})
export class EmpresaComponent implements OnInit {
  // support: Support[]
  cloud_pbx: CloudPBX[];
  empresasRouter: boolean = false;
  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    const array = this.activatedRoute.snapshot.url;
    if (array.length > 0)
      this.empresasRouter = array[0].path === 'empresas' ? true : false;
    this.cloud_pbx = cloud_pbx;
  }
}
