<section>
  <div class="welcome2">
    <h2 data-aos="fade-down" data-aos-duration="1500">
      <strong style="color: #6cace4">CELTA MOBILE</strong> A OPERADORA QUE TOCA
      A FIBRA
    </h2>
    <div class="divider" data-aos="fade-right" data-aos-duration="1500"></div>

    <p>
      Ofrecémosche as mellores tarifas para levar sempre contigo o sentimento
      celeste. <br />
      Escolle o produto que mellor se adapte ás túas necesidades, dende a Tarifa
      Benxamín ata o Pack Familia Plus. <br />Coa mellor cobertura e sempre sen
      ataduras.
    </p>
  </div>

  <!--
    <div class="slide">
        <div class="subtitle color-blue">EMPRESAS</div>
        <mat-slide-toggle class="mat-slide" aria-label="Welcome" [(ngModel)]="checked" (change)="slideChange()"></mat-slide-toggle>
        <div class="subtitle color-pink">PARTICULARES</div>
    </div> -->
</section>
