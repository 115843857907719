// Clientes
export interface Pack {
  name: string;
  price: number;
  units?: number;
  type: PackType;
  items: {
    lines: number;
    fiberSpeed: number;
    landline: boolean;
    mobileData?: string;
    extra?: string[];
  };  
}

export enum PackType {
  MOVIL = 'movil',
  FIBRA = 'fibra',
  PACK = 'pack',
}

// Empresa
// Empresa
//  type: 

