<section>
    <div class="info-container">
        <div class="links-container">
            <a href="https://www.linkedin.com/company/nexo-global-management-sl/mycompany/" target="_blank"><i class="bi bi-linkedin "></i></a>
            <a href="https://instagram.com/celtamobile" target="_blank"><i class="bi bi-instagram"></i></a>
            <a href="https://www.facebook.com/celtamobile" target="_blank"><i class="bi bi-facebook"></i></a>
        </div>

        <div class="phone-info">
            <i class="bi bi-telephone-inbound"></i>
            <a class="phone" href="tel:+34676-222-777">676 222 777</a>
        </div>
    </div>
    <div class="divider-container">
        <div class="divider"></div>
    </div>
</section>