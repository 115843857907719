import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  showCart = new BehaviorSubject<boolean>(false);
  constructor() {}

  toggleCart() {
    const state = !this.showCart.value;
    this.showCart.next(state);
  }

  openCart() {
    const val = this.showCart.value;
    if (!val) this.showCart.next(true);
    return
  }
}
