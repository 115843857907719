<div class="cookie-banner" *ngIf="untouched">
    <p>NEXO GLOBAL MANAGEMENTS, S.L. cookies propias y de terceros para analizar tu navegación con fines estadísticos y
        publicitarios. Pulsa para tener <a mat-button href="/cookies" target="_blank" class="btn">MÁS INFORMACIÓN</a> o
        para
        <button mat-button class="btn" (click)='openInfo()'>CONFIGURAR</button> O
        <button mat-button (click)='deny()' class="btn">RECHAZAR SU USO</button>. También puedes
        <button mat-raised-button color="accent" class="btn-raised" (click)='accept()'>ACEPTAR</button>
        todas las cookies y seguir navegando.
    </p>

</div>