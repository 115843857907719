import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-infointernet',
  templateUrl: './infointernet.component.html',
  styleUrls: ['./infointernet.component.scss'],
})
export class InfointernetComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    window.scroll(0, 0);
  }
}
