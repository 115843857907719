import { Component, Input, OnInit } from '@angular/core';
import { CloudPBX } from 'src/app/models/corporate.interface';
import { SelectorService } from 'src/app/services/selector.service';

@Component({
  selector: 'app-cloudpbx-card',
  templateUrl: './cloudpbx-card.component.html',
  styleUrls: ['./cloudpbx-card.component.scss'],
})
export class CloudpbxCardComponent implements OnInit {
  @Input() cloud_pbx_tariff!: CloudPBX;

  constructor(private selectorService: SelectorService) {}

  ngOnInit(): void {}

  addToCart() {
    this.selectorService.addCloudPBXToCart(this.cloud_pbx_tariff);
  }

  getInteger(num: number) {
    return Math.floor(num);
  }

  getDecimal(num: number) {
    return num.toFixed(2).split('.')[1];
  }
}
