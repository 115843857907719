import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { ResponsePostCode } from "src/app/models/address.models";
import { APIService } from "src/app/services/api.service";
import { CheckNetDialogComponent } from "./check-net-dialog/check-net-dialog.component";
import { map, startWith } from "rxjs/operators";
@Component({
  selector: "app-cobertura",
  templateUrl: "./cobertura.component.html",
  styleUrls: ["./cobertura.component.scss"],
})
export class CoberturaComponent implements OnInit {
  postCodeForm: FormGroup;
  addressForm: FormGroup;
  town: string;
  province: string;
  second_stage: boolean;
  showError: boolean = false;
  existsInDb: boolean = true;
  addresses_from_post_code: ResponsePostCode[] = [];
  loading: boolean = false;
  filteredOptions: Observable<ResponsePostCode[]>;

  constructor(
    private _fb: FormBuilder,
    private api_services: APIService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.postCodeForm = this._fb.group({
      post_code: ["", [Validators.required]],
    });

    this.addressForm = this._fb.group({
      province: [""],
      town: [""],
      street_name: ["", [Validators.required]],
      number: ["", [Validators.required]],
    });

    this.filteredOptions = this.f_2.street_name.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );

    this.postCodeForm.valueChanges.subscribe((value) => {
      if (value.post_code.length === 5) {
        this.showError = false;
        this.loading = true;
        this.submitPostCode(value.post_code);
      } else {
        this.showError = true;
        this.existsInDb = true;
        this.second_stage = false;
      }
    });
  }

  get f_1() {
    return this.postCodeForm.controls;
  }

  get f_2() {
    return this.addressForm.controls;
  }

  private _filter(value: string): ResponsePostCode[] {
    const filterValue = value.toLowerCase();

    return this.addresses_from_post_code.filter((option) => {
      const compare = option.street_type + " " + option.street_name;
      return compare.toLowerCase().includes(filterValue);
    });
  }

  submitPostCode(post_code: string) {
    this.api_services.getFromPostCode({ post_code }).subscribe((res) => {
      if (res.length > 0) {
        this.existsInDb = true;
        this.addresses_from_post_code = res;
        this.town = this.addresses_from_post_code[0].town;
        this.province = this.addresses_from_post_code[0].province;
        this.second_stage = true;
        this.loading = false;
      } else {
        this.existsInDb = false;
        this.loading = false;
      }
    });
  }

  submitAddress() {
    this.api_services
      .checkFullAddress(
        this.province,
        this.town,
        this.fixedStreetName(this.f_2.street_name.value),
        this.addressForm.value.number.toString()
      )
      .subscribe((res) => {
        // save address data in service. It will be called from check-net-dialog component before submitting email
        this.api_services.saved_province = this.province;
        this.api_services.saved_town = this.town;
        this.api_services.saved_street_name =
          this.addressForm.value.street_name;
        this.api_services.saved_street_number = this.addressForm.value.number;
        this.api_services.saved_post_code = this.postCodeForm.value.post_code;

        // open check-net-dialog component
        if (res !== undefined) {
          this.dialog.open(CheckNetDialogComponent, {
            data: res,
          });
        }
      });
  }

  streetNotFound() {
    this.api_services.saved_province = this.province;
    this.api_services.saved_town = this.town;
    this.api_services.saved_post_code = this.postCodeForm.value.post_code;

    this.dialog.open(CheckNetDialogComponent, {
      data: [],
    });
  }

  fixedStreetName(value: string) {
    const address = this.addresses_from_post_code.find(
      (a) => a.street_type + " " + a.street_name === value
    );
    if (!address) return value;
    return value.replace(address!.street_type + " ", "");
  }
}
