import { Component, OnInit } from '@angular/core';
import { Pack, PackType } from 'src/app/models/pack.interface';
import { CartService } from 'src/app/services/cart.service';
import { SelectorService } from 'src/app/services/selector.service';

@Component({
  selector: 'app-seleccionador',
  templateUrl: './seleccionador.component.html',
  styleUrls: ['./seleccionador.component.scss'],
})
export class SeleccionadorComponent implements OnInit {
  linesNumber = 0;
  fiberSlider = 0;
  fiber = 0;
  fiberSpeed = [0, 50, 100, 600];
  mainFilter = '';
  packs: Pack[] = [];
  filteredPacks: Pack[] = [];
  displayedSpeed = '';

  constructor(
    private selectorService: SelectorService,
    private cartService: CartService
  ) {}

  ngOnInit(): void {
    this.packs = this.selectorService.getAllPacks();
    this.filteredPacks = this.packs;
    this.cartService.openCart();
  }

  addLine() {
    this.linesNumber++;
    this.filterPacks();
  }
  deleteLine() {
    if (this.linesNumber === 0) {
      this.filterPacks();
      return;
    }
    this.linesNumber--;
    this.filterPacks();
  }

  changeSlider(a: any) {
    this.fiber = this.fiberSpeed[a.value];
    this.displayedSpeed = this.fiber.toString();
    this.filterPacks();
  }
  filterPacks() {
    switch (this.mainFilter) {
      case '':
        this.filteredPacks = this.selectorService.getAllPacks();
        this.filterPackByLines();
        this.filterPackByFiber();
        break;
      case PackType.MOVIL:
        this.filteredPacks = this.packs.filter(
          (p) => p.type === PackType.MOVIL
        );
        this.filterPackByLines();
        break;
      case PackType.FIBRA:
        this.filteredPacks = this.packs.filter(
          (p) => p.type === PackType.FIBRA
        );
        this.filterPackByFiber();
        break;
      case PackType.PACK:
        this.filteredPacks = this.packs.filter((p) => p.type === PackType.PACK);
        this.filterPackByLines();
        this.filterPackByFiber();
        break;
    }
  }

  filterPackByLines() {
    if (this.linesNumber === 0) return;
    this.filteredPacks = this.filteredPacks.filter(
      (p) => p.items.lines >= this.linesNumber
    );
  }
  filterPackByFiber() {
    if (this.fiber === 0) return;
    this.filteredPacks = this.filteredPacks.filter(
      (p) => p.items.fiberSpeed === this.fiber
    );
  }
}
