<div class="container">
    <mat-card>
        <mat-radio-group aria-label="Selecciones un filtro" [(ngModel)]="mainFilter" (change)="filterPacks()">
            <mat-radio-button value="">Todos</mat-radio-button>
            <mat-radio-button value="movil">Movil</mat-radio-button>
            <mat-radio-button value="fibra">Internet</mat-radio-button>
            <mat-radio-button value="pack">Internet y Móvil</mat-radio-button>
        </mat-radio-group>
        <div *ngIf="mainFilter !== 'fibra'">
            <h2 style="text-align: center;">Líneas</h2>
            <div class="line-selector">
                <button mat-mini-fab color="accent" (click)="deleteLine()">
                    <mat-icon>remove</mat-icon>
                </button>
                <p>{{linesNumber}}</p>
                <button mat-mini-fab color="accent" (click)="addLine()">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </div>
        <div class="fiber-container" *ngIf="mainFilter !== 'movil'">
            <h2>{{fiber === 0 ? 'Todas las Velocidades de Internet' : displayedSpeed + 'MB'}}</h2>
            <mat-slider [max]="fiberSpeed.length - 1" [min]="0" [step]="1" [(ngModel)]="fiberSlider"
                (input)="changeSlider($event)">
            </mat-slider>
            <p>Elige tu velocidad moviendo la barra.</p>
        </div>
    </mat-card>
    <div class="pack-container">
        <app-tariff-card [packData]="pack" *ngFor="let pack of filteredPacks"></app-tariff-card>
    </div>
</div>