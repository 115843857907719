<section>
  <article class="terms-and-conditions page type-page status-publish hentry">
    <div class="entry-content">
      <p>&nbsp;</p>
      <h1 class="titulo-pagina" style="text-align: center; line-height: 80px">
        Términos y condiciones
      </h1>
      <p>&nbsp;</p>
      <p>
        <span style="font-weight: 400"
          >Las presentes Condiciones Generales tienen por objeto regular las
          condiciones de uso de la plataforma de pago cuyo titular es NEXO
          GLOBAL MANAGEMENT S.L., con CIF B-97341499, con domicilio fiscal en
          Calle Carboner 40, Parque Táctica 46988 Paterna
          (Valencia).&nbsp;</span
        >
      </p>
      <p>&nbsp;</p>
      <p><b>INFORMACIÓN GENERAL</b></p>
      <p>
        <span style="font-weight: 400"
          >NEXO GLOBAL MANAGEMENT S.L., con CIF B-97341499, con domicilio fiscal
          en Calle Carboner 40, Parque Táctica 46988 Paterna, es una empresa
          dedicada al sector de las telecomunicaciones y telefonía móvil que
          opera en el ámbito nacional de España.&nbsp;&nbsp;</span
        >
      </p>
      <p>&nbsp;</p>
      <p><b>TÉRMINOS DE LA COMPRA ON LINE</b></p>
      <p>
        <span style="font-weight: 400"
          >El usuario debe tener al menos 18 años para poder utilizar y adquirir
          cualquier producto o servicio de los ofrecidos por la web. DERECHO DE
          DESISTIMIENTO</span
        >
      </p>
      <p>
        <span style="font-weight: 400"
          >El cliente tiene derecho a desistir del presente contrato en un plazo
          de 14 días naturales sin necesidad de justificación.</span
        >
      </p>
      <p>
        <span style="font-weight: 400"
          >El plazo de desistimiento expirará a los 14 días naturales del día en
          que se le haya hecho entrega del bien o servicio.</span
        >
      </p>
      <p>
        <span style="font-weight: 400"
          >Para ejercer el derecho de desistimiento, el cliente deberá notificar
          su decisión de desistir del contrato a través de una declaración
          inequívoca escribiendo un email a la dirección </span
        ><a href="mailto:contacto@nexomobile.es"
          ><span style="font-weight: 400">contacto@nexo.es</span></a
        ><span style="font-weight: 400"> .&nbsp;</span>
      </p>
      <p>
        <span style="font-weight: 400"
          >Para cumplir el plazo de desistimiento, basta con que la comunicación
          relativa al ejercicio por su parte de este derecho sea realizada antes
          de que venza el plazo correspondiente.</span
        >
      </p>
      <p>&nbsp;</p>
      <p><b>SEGURIDAD DE LA COMPRA ON LINE</b></p>
      <p>
        <span style="font-weight: 400"
          >El sitio web utiliza técnicas de seguridad de la información
          generalmente aceptada en la industria, tales como firewalls,
          procedimientos de control de acceso y mecanismos criptográficos, todo
          ello con el objeto de evitar el acceso no autorizado de datos. Para
          lograr estos fines, el usuario/cliente acepta que el prestador obtenga
          datos para efecto de la correspondiente autenticación de los controles
          de acceso.</span
        >
      </p>
      <p><b>POLÍTICA DE ENTREGA</b></p>
      <p>
        <span style="font-weight: 400"
          >El período de entrega del pedido podrá variar en función de las
          siguientes circunstancias:&nbsp;</span
        >
      </p>
      <ul>
        <li style="font-weight: 400">
          <span style="font-weight: 400"
            >Tipo de pedido (alta, migración, portabilidad, etc.)</span
          >
        </li>
        <li style="font-weight: 400">
          <span style="font-weight: 400"
            >Hora y día en que se registre la solicitud en el
            sistema.&nbsp;</span
          >
        </li>
      </ul>
      <p>
        <span style="font-weight: 400"
          >El pedido se enviará en un período de entre uno y tres días
          laborables siempre que exista disponibilidad de este.&nbsp; En caso
          contrario, el usuario recibirá la información del tiempo estimado para
          realizar el envío.&nbsp;</span
        >
      </p>
      <p>
        <span style="font-weight: 400"
          >El pedido se entregará mediante mensajero en la dirección que se haya
          indicado en el momento de contratación del producto.&nbsp; El pedido
          deberá ser recogido por la persona que haya realizado la
          solicitud.&nbsp;
        </span>
      </p>
    </div>
    <!-- .entry-content -->
  </article>
</section>
