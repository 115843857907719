import { Component, OnInit } from '@angular/core';
import {
  SlideToggleService,
  WebType,
} from 'src/app/services/slide-toggle.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css'],
})
export class WelcomeComponent implements OnInit {
  checked = true;
  constructor(private slideToggleService: SlideToggleService) {}

  ngOnInit(): void {
    this.slideToggleService.webType.subscribe((wt) => {
      this.checked = wt === WebType.PARTICULAR;
    });
  }
  slideChange() {
    this.slideToggleService.change();
  }
}
