<section>
    <div class="partners-container">
        <h1>NUESTROS <strong style="color: #E72986">PARTNERS</strong></h1>
    </div>
    <div class="img-container">
        <a href="https://www.apple.com/" target="_blank" rel="noopener noreferrer"> <img
                src="/assets/IMG/Patners/logo_partner1.png" alt=""></a>
        <a href="https://www.asterisk.org/" target="_blank" rel="noopener noreferrer"> <img
                src="/assets/IMG/Patners/logo_partner2.png" alt=""></a>
        <a href="https://www.cisco.com/" target="_blank" rel="noopener noreferrer"> <img
                src="/assets/IMG/Patners/logo_partner3.png" alt=""></a>
        <a href="https://www.microsoft.com/" target="_blank" rel="noopener noreferrer"> <img
                src="/assets/IMG/Patners/logo_partner7.png" alt=""></a>
        <a href="https://www.sangoma.com/" target="_blank" rel="noopener noreferrer"> <img
                src="/assets/IMG/Patners/logo_partner4.png" alt=""></a>
        <a href="https://www.elastix.org/" target="_blank" rel="noopener noreferrer"> <img
                src="/assets/IMG/Patners/logo_partner5.png" alt=""></a>
        <a href="http://www.grandstream.com/" target="_blank" rel="noopener noreferrer"> <img
                src="/assets/IMG/Patners/logo_partner6.png" alt=""></a>

        <a href="https://mikrotik.com/" target="_blank" rel="noopener noreferrer"> <img
                src="/assets/IMG/Patners/logo_partner8.png" alt=""></a>
        <a href="https://www.tp-link.com/" target="_blank" rel="noopener noreferrer"> <img
                src="/assets/IMG/Patners/logo_partner9.png" alt=""></a>
        <a href="https://www.yealink.com/" target="_blank" rel="noopener noreferrer"> <img
                src="/assets/IMG/Patners/Yealink-LOGO.png" alt=""></a>
    </div>
    <div class="CNMC-container">
        <img class="CNMC-image" src="/assets/IMG/Patners/logoCNMC.png" alt="" width="300px">
        <h5>Somos una empresa debidamente inscrita en el resgistro de Operadores por la Comisión
            Nacional de los Mercados y la Competencia.
        </h5>
    </div>
</section>