import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss'],
})
export class CookieBannerComponent implements OnInit {
  untouched = true;
  allowed = false;
  constructor(private cookieService: CookieService, public dialog: MatDialog) {}

  ngOnInit(): void {
    let item = localStorage.getItem('cookies');
    this.untouched = item === null;

    if (item) {
      let data = JSON.parse(item);
      if (!data.allowed) {
        this.cookieService.deleteAll();
      }
      if (data.allowed && data.list > 0) {
        if (!data.list[1]) this.cookieService.deleteAll(); //eliminar las de terceros
      }
    }
  }

  accept() {
    localStorage.setItem(
      'cookies',
      JSON.stringify({ allowed: true, list: [] })
    );
    this.untouched = false;
  }

  deny() {
    localStorage.setItem(
      'cookies',
      JSON.stringify({ allowed: false, list: [] })
    );
    this.untouched = false;
    this.cookieService.deleteAll();
  }

  openInfo() {
    const dialogRef = this.dialog.open(CookiesConfigComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        this.deny();
        return;
      }

      if (!result.propias && !result.terceros) {
        this.deny();
        return;
      }
      if (result.propias && result.terceros) {
        this.accept();
        return;
      }

      localStorage.setItem(
        'cookies',
        JSON.stringify({
          allowed: true,
          list: [{ propias: result.propias, terceros: result.terceros }],
        })
      );
      this.untouched = false;
    });
  }
}

@Component({
  selector: 'cookies-config',
  templateUrl: 'cookies-config.html',
})
export class CookiesConfigComponent {
  propias = false;
  terceros = false;
  constructor(public dialogRef: MatDialogRef<CookiesConfigComponent>) {}
}
