import { CloudPBX, Support } from "../models/corporate.interface";

export const support: Support[] = [
  {
    name: "Pack Esencial",
    price: 9,
    assistance: 1,
  },
  {
    name: "Pack Profesional",
    price: 19,
    assistance: 3,
  },
  {
    name: "Pack Avanzado",
    price: 28,
    assistance: 5,
  },
  {
    name: "Pack Soporte 1h",
    price: 60,
    assistance: 0,
    extra:
      "Bolsa de 1 hora de asistencia por conexión remota. Un único pago, caducidad 12 meses",
  },
];

export const cloud_pbx: CloudPBX[] = [
  // {
  //     name: 'Esencial',
  //     price: 20,
  //     ddi: 1,
  //     minutes_landline: 1000,
  //     minutes_mobile: 0,
  //     concurrent_calls: 2,
  //     fax: 0,
  //     switchboard: true,
  //     control_panel: false
  // },
  // {
  //     name: 'Esencial Plus',
  //     price: 30,
  //     ddi: 2,
  //     minutes_landline: 1000,
  //     minutes_mobile: 200,
  //     concurrent_calls: 3,
  //     fax: 0,
  //     switchboard: true,
  //     control_panel: true
  // },
  // {
  //     name: 'Profesional',
  //     price: 49.50,
  //     ddi: 2,
  //     minutes_landline: 1000,
  //     minutes_mobile: 250,
  //     concurrent_calls: 5,
  //     fax: 1,
  //     switchboard: true,
  //     control_panel: true
  // },
  // {
  //     name: 'Profesional Plus',
  //     price: 59.50,
  //     ddi: 3,
  //     minutes_landline: 2000,
  //     minutes_mobile: 500,
  //     concurrent_calls: 5,
  //     fax: 1,
  //     switchboard: true,
  //     control_panel: true
  // },
  {
    name: "Pack Centralita Avanzada",
    price: 79.95,
    ddi: 2,
    minutes_landline: 1500,
    minutes_mobile: 500,
    concurrent_calls: 5,
    fax: 0,
    switchboard: true,
    control_panel: true,
  },
  {
    name: "Pack Centralita Avanzada Plus",
    price: 96.9,
    ddi: 5,
    minutes_landline: 3000,
    minutes_mobile: 1000,
    concurrent_calls: 10,
    fax: 1,
    switchboard: true,
    control_panel: true,
  },
];
