<app-slider></app-slider>
<app-welcome></app-welcome>

<ng-container *ngIf="webType === 'PARTICULAR'; else empresas">
  <app-offers></app-offers>
</ng-container>

<ng-template #empresas>
  <app-our-services></app-our-services>
</ng-template>

<app-sorteos></app-sorteos>
<app-aboutus></app-aboutus>
<app-appmobile></app-appmobile>
