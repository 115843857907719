import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
//Componentes
import { SeleccionadorComponent } from "./components/seleccionador/seleccionador.component";
import { Error404pageComponent } from "./components/error404page/error404page.component";
import { HomeComponent } from "./components/home/home.component";
import { ContactUsComponent } from "./components/contact-us/contact-us.component";
import { EmpresaComponent } from "./components/empresa/empresa.component";
import { GeneralConditionsComponent } from "./components/general-conditions/general-conditions.component";
import { TermsAndConditionsComponent } from "./components/terms-and-conditions/terms-and-conditions.component";
import { AvisolegalComponent } from "./components/avisolegal/avisolegal.component";
import { LegalPrivacidadComponent } from "./components/legal-privacidad/legal-privacidad.component";
import { CoberturaComponent } from "./components/cobertura/cobertura.component";
import { InfoempresasComponent } from "./components/infoempresas/infoempresas.component";

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "tariff-selector", component: SeleccionadorComponent },
  { path: "contacta", component: ContactUsComponent },
  { path: "empresas", component: EmpresaComponent },
  { path: "legales", component: GeneralConditionsComponent },
  { path: "condiciones-contratacion", component: TermsAndConditionsComponent },
  { path: "aviso-legal", component: AvisolegalComponent },
  { path: "politica-de-privacidad", component: LegalPrivacidadComponent },
  { path: "cobertura", component: CoberturaComponent },
  { path: "centralita", component: InfoempresasComponent },

  { path: "**", component: Error404pageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
