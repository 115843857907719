import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Pack } from "src/app/models/pack.interface";
import { CartService } from "src/app/services/cart.service";
import { SelectorService } from "src/app/services/selector.service";

@Component({
  selector: "app-offers",
  templateUrl: "./offers.component.html",
  styleUrls: ["./offers.component.css"],
})
export class OffersComponent implements OnInit {
  packs: Pack[] = [];
  destacados : Pack[] = []
  showInfo = false

  constructor(
    private selectorService: SelectorService,
    private cartService: CartService,
    private _snackBar: MatSnackBar,
    private dialog:MatDialog
  ) {}

  ngOnInit(): void {
    this.packs = this.selectorService.getAllPacks();
    this.destacados = this.packs.filter(p=>p.name === 'PACK GOL' || p.name === 'PACK MARCADOR' || p.name === 'PACK TRIBUNA' || p.name === 'PACK FAMILIA PLUS')
  }

  getTariffFromString(pack_name: string) {
    let selected_pack: Pack | undefined = this.packs.find(
      (item) => item.name == pack_name
    );
    if (selected_pack) {
      this.selectorService.addToCart(selected_pack);
      this._snackBar.open("¡" + selected_pack.name + " añadido!", "Cerrar", {
        duration: 2000,
      });
    }
  }
  // openDialog(){
  //   this.dialog.open(DialogContentExampleDialog)
  // }
}

// @Component({
//   selector: 'dialog-content-example-dialog',
//   template: `<h2 mat-dialog-title>Install Angular</h2>
//   <mat-dialog-content class="mat-typography">
//     informacioooooooooooooooooooon
//   </mat-dialog-content>
//   <mat-dialog-actions align="end">  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Cerrar</button>
//   </mat-dialog-actions>`,
// })
// export class DialogContentExampleDialog {}
