import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  SlideToggleService,
  WebType,
} from 'src/app/services/slide-toggle.service';

@Component({
  selector: 'app-appmobile',
  templateUrl: './appmobile.component.html',
  styleUrls: ['./appmobile.component.css'],
})
export class AppmobileComponent implements OnInit, OnDestroy {
  suscription: Subscription;
  webParticular: boolean;
  constructor(private sliderToggle: SlideToggleService) {}

  ngOnInit(): void {
    this.suscription = this.sliderToggle.webType.subscribe((wt) => {
      this.webParticular = wt === WebType.PARTICULAR;
    });
  }
  ngOnDestroy() {
    this.suscription.unsubscribe();
  }
}
