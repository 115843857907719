import { Component, Input, OnInit } from '@angular/core';
import { Support } from 'src/app/models/corporate.interface';
import { SelectorService } from 'src/app/services/selector.service';

@Component({
  selector: 'app-support-card',
  templateUrl: './support-card.component.html',
  styleUrls: ['./support-card.component.scss']
})
export class SupportCardComponent implements OnInit {

  @Input() supp_tariff!: Support;

  constructor(private selectorService: SelectorService) {}

  addToCart(){
    console.log('add to cart')
  }

  ngOnInit(): void {
  }

}
